<template>
  <div class="flex xs12 sm12">
    <va-card
      :style="computedStyle"
      :class="computedClass"
      :title="$t(`menu.notifications`)">
      <div class="actions">
        <va-button flat icon="at-icon l_notification_done" color="info" @click="markAllAsRead">{{ $t('notifications.mark_all_as_read') }}</va-button>
        <va-button flat icon="at-icon l_trash" color="danger" @click="deleteAll"> {{ $t('notifications.delete_all') }}</va-button>
      </div>
      <div class="flex xs12">
        <at-data-table
          ref="table"
          :apiUrl="apiUrl"
          :apiMode="apiMode"
          :fields="fields"
          :defaultPerPage="defaultTablePerPage"
          dataPath="data"
          paginationPath="pagination"
          :filter-input-shown="true"
          :per-page-selector-shown="true"
          :sort-order="sortOrder"
        >
          <!-- <template slot="select" slot-scope="props">
            <va-checkbox :value="props.rowData.checked" @input="select(props.rowData)"/>
          </template> -->
          <template slot="icon" slot-scope="props">
            <va-icon :style="props.rowData.unread ? 'font-size: 1.8rem' : 'font-size: 1.5rem'" :color="setColor(props.rowData.level)" :name="setIcon(props.rowData.level)"/>
          </template>
          <template slot="message" slot-scope="props">
            <span :class="{'text--bold': props.rowData.unread}">{{ props.rowData.message }}</span>
          </template>
          <template slot="date" slot-scope="props">
            <span :class="{'text--bold': props.rowData.unread}" v-html="printTimeStamp(props.rowData.updated_at)"></span>
          </template>
          <template slot="actions" slot-scope="props">
            <va-popover v-if="props.rowData.unread" :message="`${$t('notifications.mark_as_read')}`" placement="top">
              <va-button flat small color="gray" icon="at-icon l_notification_done" @click="markOneAsRead(props.rowData)" />
            </va-popover>
            <va-popover :message="`${$t('notifications.delete')}`" placement="top">
              <va-button flat small color="gray" icon="at-icon l_trash" @click="deleteOne(props.rowData)" />
            </va-popover>
          </template>
        </at-data-table>
      </div>
    </va-card>
  </div>
</template>

<script>
import axios from 'axios'
import * as socketio from '../../utilities/socketio'
import { getTimeDateFormat } from '../../services/atmosphere-ui/helpers/TimeHelpers'

export default {
  name: 'notifications',
  data () {
    return {
      apiMode: true,
      apiUrl: `${socketio.notificationsApiUrl}/users/${this.getUserId()}/notifications/context/${this.getContextId()}`,
      term: null,
      selectedDateArray: null,
      defaultTablePerPage: '10',
      sortOrder: [{
        field: 'updated_at',
        direction: 'desc',
      }],
    }
  },
  created () {
    this.$level.value = 'bird'
  },
  mounted () {
    // this.listenNotifications()
  },
  methods: {
    getUserId () {
      return 1 // hardcoded for testing
    },
    getContextId () {
      return 'f1' // hardcoded for testing
    },
    printTimeStamp (timestampUTC) {
      return getTimeDateFormat(timestampUTC + 'Z', 'long', true)
    },
    setColor (level) {
      let color = 'info'

      switch (level) {
        case 0:
          color = 'info'
          break

        case 1:
          color = 'warning'
          break

        case 2:
          color = 'danger' // error
          break

        case 3:
          color = 'danger' // old
          break
      }

      return color
    },
    setIcon (level) {
      let icon = ''

      switch (level) {
        case 0:
          icon = 'at-icon l_notification_text'
          break

        case 1:
          icon = 'at-icon l_alert'
          break

        case 2:
          icon = 'at-icon l_alert'
          break

        case 3:
          icon = 'at-icon l_alert'
          break
      }
      return icon
    },
    select (notification) {
      const idx = this.notifications.findIndex(u => u.id === notification.id)
      this.notifications[idx].checked = !this.notifications[idx].checked
    },
    deleteOne (notification) {
      axios.delete(`${socketio.notificationsApiUrl}/users/${this.getUserId()}/notifications/${notification.id}`)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            this.launchToast(response.data.message)
            this.$refs.table.$refs.vuetable.refresh() // the delete action can change the pagination
          } else if (response.status >= 200 && response.status < 300) {
            this.launchToast('Notification deleted.')
            this.$refs.table.$refs.vuetable.refresh() // the delete action can change the pagination
          } else {
            console.log(response)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    deleteAll () {
      axios.delete(`${socketio.notificationsApiUrl}/users/${this.getUserId()}/notifications/`)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            this.launchToast(response.data.message)
            this.$refs.table.$refs.vuetable.refresh()
          } else if (response.status >= 200 && response.status < 300) {
            this.launchToast('All notifications deleted.')
            this.$refs.table.$refs.vuetable.refresh()
          } else {
            console.log(response)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    markOneAsRead (notification) {
      axios.put(`${socketio.notificationsApiUrl}/users/${this.getUserId()}/notifications/${notification.id}/mark-as-read/`)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            this.launchToast('Notification marked as read.')
            this.$refs.table.$refs.vuetable.reload()
          } else {
            console.log(response)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    markAllAsRead () {
      axios.put(`${socketio.notificationsApiUrl}/users/${this.getUserId()}/notifications/mark-as-read/`)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            this.launchToast(response.data.message)
            this.$refs.table.$refs.vuetable.refresh() // go to page 1 to show that all were marked as read
          } else if (response.status >= 200 && response.status < 300) {
            this.launchToast('All notifications marked as read.')
            this.$refs.table.$refs.vuetable.refresh() // go to page 1 to show that all were marked as read
          } else {
            console.log(response)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    listenNotifications () {
      socketio.initialiseSocketNotifications()
      socketio.socketNotifications.emit('subscribe_to_context', [`user${this.getUserId()}`])
      socketio.socketNotifications.on('notify', data => {
        console.log(data)
        // this.launchToast(data)
        // this.$refs.table.$refs.vuetable.refresh()
      })
    },
    launchToast (text) {
      this.showToast(
        text,
        {
          theme: 'none',
          position: 'top-center',
          duration: 4500,
          fullWidth: true,
        },
      )
    },
  },
  computed: {
    computedClass () {
      return {
        'va-card--danger': this.$status.value === 'danger',
      }
    },
    computedStyle () {
      const style = {
        /* Update styles via JS Helpers (currently they are being changed in a global watcher) */
        // '--primary-color-hue': hex2hsl(this.$themes.primary).h,
        // '--primary-color': this.$themes.primary,

        /* Alternative: Update styles via JS Helpers */
        // background: cardGradient(this.$themes.primary, this.$status.value, this.$mode.value),
        // boxShadow: genShadow(this.$themes.primary, this.$status.value, this.$mode.value),
        // borderRadius: genRadius({ topLeft: '24px', bottomRight: '24px' }),
      }
      return style
    },
    fields () {
      const tableFields = [{
        //   name: '__slot:select',
        // },
        // {
        name: '__slot:icon',
        title: this.$t('tables.headings.alertSeverity'),
        // sortField: 'level', TODO
        titleClass: 'text-center',
        dataClass: 'text-center',
        width: '15%',
      },
      {
        name: '__slot:message',
        title: this.$t('tables.headings.message'),
        width: '60%',
      },
      {
        name: '__slot:date',
        title: this.$t('tables.headings.date'),
        sortField: 'updated_at',
        width: '15%',
      }, {
        name: '__slot:actions',
        width: '10%',
      }]
      return tableFields
    },
  },
}
</script>

<style lang="scss">
.actions {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}
</style>
